import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import navigate from "lib/navigate";
//import moment from 'moment';

import {
  arrangeData,
  encodeJsonString,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";

class DownloadDisplayComp extends View {
  constructor(props) {
    super(props);
  }

  @autobind
  showDetail(e) {
    e.preventDefault();
    var url = "/downloadDetail?sn="+ e.target.dataset.sn;
    console.log(url);
    console.log(e.target.name);
    console.log(e.target.value);
    console.log(e.target.dataset.sn);
    navigate(url);
  }

  render() {
    let {
      displayConfig,
      data,
      resourceData,
      functionConfigList,
      functionRightConfigList,
      buttonEvent,
      batchConfig,
      rowStyle,
      serialNo,
      auth,
      handleClick,
      displayCheckBox,
      i18n,
      t,
      info,
      refetch,
    } = this.props;

    const arrangedData = arrangeData(data);

    const { sn, name, onlinedate } = arrangedData;

    let { language } = i18n;

    return (
      <>
        <li className={`${rowStyle}`}>
          <a
            tabIndex="0"
            href="#"
            data-sn={sn}
            //name="test"
            onClick={this.showDetail.bind(this)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.showDetail(e);
              }
            }}>
            <span className="num">{serialNo}</span>
            {name}
          </a>
          <time>{onlinedate}</time>
        </li>
      </>
    );
  }
}

DownloadDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

DownloadDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default DownloadDisplayComp;
